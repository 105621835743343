import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Flex from "app/components/Flex";
import FeaturedClass from "./FeaturedClass";

const DescriptionCard = styled.div`
  white-space: pre-line;
  ${({ theme }) => theme.components.card}
`;

const Title = styled.h4`
  margin: 0 0 20px;
  font-size: 18px;
`;

const InstructorBody = ({ instructor }) => (
  <Flex my="4" flexDirection={["column", "column", "row"]}>
    <Flex flex="1" alignItems="flex-start" pl={[0, 0, 3]}>
      <FeaturedClass
        selectedFrom="FeaturedClass"
        classId={instructor.featured_class}
      />
    </Flex>
    <Flex flexDirection="column" flex="1" py={[3, 3, 0]} px="3">
      <DescriptionCard>
        <Title>Credits</Title>
        {instructor.credits}
      </DescriptionCard>
      <DescriptionCard>
        <Title>About {instructor.name}</Title>
        {instructor.bio ? instructor.bio : "Coming soon."}
      </DescriptionCard>
    </Flex>
  </Flex>
);

InstructorBody.propTypes = {
  instructor: PropTypes.shape({}).isRequired,
};

export default InstructorBody;
